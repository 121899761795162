import { default as energy_45green_45practicesn4GXlE7Pu2Meta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue?macro=true";
import { default as fun_45part_45journeyqeZcNOAnzrMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue?macro=true";
import { default as homesafe_45colorado_45master_45builderTxkyRMkOCwMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue?macro=true";
import { default as indexdB1CaDgQWcMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45teamRdME9zwaySMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as preferred_45lenderX1A2ChVZepMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue?macro=true";
import { default as realtor_45resourcescZR42uqyIJMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue?macro=true";
import { default as _91post_93VlATJbhLeIMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexfUjNnn9iLkMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as indexJXWhheY2BDMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93EJ8grKitcXMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexEgANPfUlvDMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as ask_45a_45questionM1jKQ1nTwSMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunitiescWGOAox7uIMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faqrbjcxjzDVZMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourcesUT7q7Ag3mDMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexXvY8Byt4GkMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locationsBfxXhWcfW5Meta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as current_45offers0r9dR2LZVWMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexZHWn92kIjcMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indexHBQJiE6CalMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/events/index.vue?macro=true";
import { default as index1Cxov2YPbtMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as indexv3lm1mldsKMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue?macro=true";
import { default as indexKegFKNgo9tMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue?macro=true";
import { default as index4uajthAnQPMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue?macro=true";
import { default as index2vLPRVNye1Meta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/index.vue?macro=true";
import { default as knolls_45hoa_45informationosLbhtWRDiMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue?macro=true";
import { default as land_45acquisitionClmTfmeYIoMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as brents_45placefGazewesEpMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue?macro=true";
import { default as experience_45lower_45maintenanceqht5ak62CeMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue?macro=true";
import { default as _91testimonial_93ITqA7XMkGEMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as index9kWikpUYpOMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexE9vGpgCd3iMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as whats_45lifefullnessyiDRAb4KH9Meta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue?macro=true";
import { default as _91_46_46_46slug_93epe3D61iYlMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as my_45favoritesDXNXtl56t3Meta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45searchF2uEf1RyWGMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as easyhouseOwSyQcjp14Meta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededitionoo6MslNYA7Meta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottage9WMpstyJf8Meta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as indexrTWPO4Na4VMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as our_45neighborhoodsEPgCLkYf3QMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue?macro=true";
import { default as partner_45with_45usgLJbOzxj81Meta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue?macro=true";
import { default as privacytermsn6n23K94bqMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as sandbox4FoKU4KJEgMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as _91_46_46_46slug_93OfSJz3W8ZrMeta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue?macro=true";
import { default as component_45stubQQN2lCIRr2Meta } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubQQN2lCIRr2 } from "/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: energy_45green_45practicesn4GXlE7Pu2Meta?.name ?? "about-us-energy-green-practices",
    path: energy_45green_45practicesn4GXlE7Pu2Meta?.path ?? "/about-us/energy-green-practices",
    meta: energy_45green_45practicesn4GXlE7Pu2Meta || {},
    alias: energy_45green_45practicesn4GXlE7Pu2Meta?.alias || [],
    redirect: energy_45green_45practicesn4GXlE7Pu2Meta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue").then(m => m.default || m)
  },
  {
    name: fun_45part_45journeyqeZcNOAnzrMeta?.name ?? "about-us-fun-part-journey",
    path: fun_45part_45journeyqeZcNOAnzrMeta?.path ?? "/about-us/fun-part-journey",
    meta: fun_45part_45journeyqeZcNOAnzrMeta || {},
    alias: fun_45part_45journeyqeZcNOAnzrMeta?.alias || [],
    redirect: fun_45part_45journeyqeZcNOAnzrMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue").then(m => m.default || m)
  },
  {
    name: homesafe_45colorado_45master_45builderTxkyRMkOCwMeta?.name ?? "about-us-homesafe-colorado-master-builder",
    path: homesafe_45colorado_45master_45builderTxkyRMkOCwMeta?.path ?? "/about-us/homesafe-colorado-master-builder",
    meta: homesafe_45colorado_45master_45builderTxkyRMkOCwMeta || {},
    alias: homesafe_45colorado_45master_45builderTxkyRMkOCwMeta?.alias || [],
    redirect: homesafe_45colorado_45master_45builderTxkyRMkOCwMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue").then(m => m.default || m)
  },
  {
    name: indexdB1CaDgQWcMeta?.name ?? "about-us",
    path: indexdB1CaDgQWcMeta?.path ?? "/about-us",
    meta: indexdB1CaDgQWcMeta || {},
    alias: indexdB1CaDgQWcMeta?.alias || [],
    redirect: indexdB1CaDgQWcMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45teamRdME9zwaySMeta?.name ?? "about-us-our-team",
    path: our_45teamRdME9zwaySMeta?.path ?? "/about-us/our-team",
    meta: our_45teamRdME9zwaySMeta || {},
    alias: our_45teamRdME9zwaySMeta?.alias || [],
    redirect: our_45teamRdME9zwaySMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: preferred_45lenderX1A2ChVZepMeta?.name ?? "about-us-preferred-lender",
    path: preferred_45lenderX1A2ChVZepMeta?.path ?? "/about-us/preferred-lender",
    meta: preferred_45lenderX1A2ChVZepMeta || {},
    alias: preferred_45lenderX1A2ChVZepMeta?.alias || [],
    redirect: preferred_45lenderX1A2ChVZepMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue").then(m => m.default || m)
  },
  {
    name: realtor_45resourcescZR42uqyIJMeta?.name ?? "about-us-realtor-resources",
    path: realtor_45resourcescZR42uqyIJMeta?.path ?? "/about-us/realtor-resources",
    meta: realtor_45resourcescZR42uqyIJMeta || {},
    alias: realtor_45resourcescZR42uqyIJMeta?.alias || [],
    redirect: realtor_45resourcescZR42uqyIJMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue").then(m => m.default || m)
  },
  {
    name: _91post_93VlATJbhLeIMeta?.name ?? "blog-post",
    path: _91post_93VlATJbhLeIMeta?.path ?? "/blog/:post()",
    meta: _91post_93VlATJbhLeIMeta || {},
    alias: _91post_93VlATJbhLeIMeta?.alias || [],
    redirect: _91post_93VlATJbhLeIMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: indexfUjNnn9iLkMeta?.name ?? "blog-category-category",
    path: indexfUjNnn9iLkMeta?.path ?? "/blog/category/:category()",
    meta: indexfUjNnn9iLkMeta || {},
    alias: indexfUjNnn9iLkMeta?.alias || [],
    redirect: indexfUjNnn9iLkMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexJXWhheY2BDMeta?.name ?? "blog",
    path: indexJXWhheY2BDMeta?.path ?? "/blog",
    meta: indexJXWhheY2BDMeta || {},
    alias: indexJXWhheY2BDMeta?.alias || [],
    redirect: indexJXWhheY2BDMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93EJ8grKitcXMeta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_93EJ8grKitcXMeta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93EJ8grKitcXMeta || {},
    alias: _91_46_46_46slug_93EJ8grKitcXMeta?.alias || [],
    redirect: _91_46_46_46slug_93EJ8grKitcXMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexEgANPfUlvDMeta?.name ?? "campaigns",
    path: indexEgANPfUlvDMeta?.path ?? "/campaigns",
    meta: indexEgANPfUlvDMeta || {},
    alias: indexEgANPfUlvDMeta?.alias || [],
    redirect: indexEgANPfUlvDMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: ask_45a_45questionM1jKQ1nTwSMeta?.name ?? "contact-us-ask-a-question",
    path: ask_45a_45questionM1jKQ1nTwSMeta?.path ?? "/contact-us/ask-a-question",
    meta: ask_45a_45questionM1jKQ1nTwSMeta || {},
    alias: ask_45a_45questionM1jKQ1nTwSMeta?.alias || [],
    redirect: ask_45a_45questionM1jKQ1nTwSMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunitiescWGOAox7uIMeta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunitiescWGOAox7uIMeta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunitiescWGOAox7uIMeta || {},
    alias: career_45opportunitiescWGOAox7uIMeta?.alias || [],
    redirect: career_45opportunitiescWGOAox7uIMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: faqrbjcxjzDVZMeta?.name ?? "contact-us-faq",
    path: faqrbjcxjzDVZMeta?.path ?? "/contact-us/faq",
    meta: faqrbjcxjzDVZMeta || {},
    alias: faqrbjcxjzDVZMeta?.alias || [],
    redirect: faqrbjcxjzDVZMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: homeowner_45resourcesUT7q7Ag3mDMeta?.name ?? "contact-us-homeowner-resources",
    path: homeowner_45resourcesUT7q7Ag3mDMeta?.path ?? "/contact-us/homeowner-resources",
    meta: homeowner_45resourcesUT7q7Ag3mDMeta || {},
    alias: homeowner_45resourcesUT7q7Ag3mDMeta?.alias || [],
    redirect: homeowner_45resourcesUT7q7Ag3mDMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: indexXvY8Byt4GkMeta?.name ?? "contact-us",
    path: indexXvY8Byt4GkMeta?.path ?? "/contact-us",
    meta: indexXvY8Byt4GkMeta || {},
    alias: indexXvY8Byt4GkMeta?.alias || [],
    redirect: indexXvY8Byt4GkMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45locationsBfxXhWcfW5Meta?.name ?? "contact-us-our-locations",
    path: our_45locationsBfxXhWcfW5Meta?.path ?? "/contact-us/our-locations",
    meta: our_45locationsBfxXhWcfW5Meta || {},
    alias: our_45locationsBfxXhWcfW5Meta?.alias || [],
    redirect: our_45locationsBfxXhWcfW5Meta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: current_45offers0r9dR2LZVWMeta?.name ?? "current-offers",
    path: current_45offers0r9dR2LZVWMeta?.path ?? "/current-offers",
    meta: current_45offers0r9dR2LZVWMeta || {},
    alias: current_45offers0r9dR2LZVWMeta?.alias || [],
    redirect: current_45offers0r9dR2LZVWMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: indexZHWn92kIjcMeta?.name ?? "events-event",
    path: indexZHWn92kIjcMeta?.path ?? "/events/:event()",
    meta: indexZHWn92kIjcMeta || {},
    alias: indexZHWn92kIjcMeta?.alias || [],
    redirect: indexZHWn92kIjcMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHBQJiE6CalMeta?.name ?? "events",
    path: indexHBQJiE6CalMeta?.path ?? "/events",
    meta: indexHBQJiE6CalMeta || {},
    alias: indexHBQJiE6CalMeta?.alias || [],
    redirect: indexHBQJiE6CalMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index1Cxov2YPbtMeta?.name ?? "homes-location-community",
    path: index1Cxov2YPbtMeta?.path ?? "/homes/:location()/:community()",
    meta: index1Cxov2YPbtMeta || {},
    alias: index1Cxov2YPbtMeta?.alias || [],
    redirect: index1Cxov2YPbtMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: indexv3lm1mldsKMeta?.name ?? "homes-location-community-models-model",
    path: indexv3lm1mldsKMeta?.path ?? "/homes/:location()/:community()/models/:model()",
    meta: indexv3lm1mldsKMeta || {},
    alias: indexv3lm1mldsKMeta?.alias || [],
    redirect: indexv3lm1mldsKMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKegFKNgo9tMeta?.name ?? "homes-location-community-plans-plan",
    path: indexKegFKNgo9tMeta?.path ?? "/homes/:location()/:community()/plans/:plan()",
    meta: indexKegFKNgo9tMeta || {},
    alias: indexKegFKNgo9tMeta?.alias || [],
    redirect: indexKegFKNgo9tMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: index4uajthAnQPMeta?.name ?? "homes-location-community-qmi-home",
    path: index4uajthAnQPMeta?.path ?? "/homes/:location()/:community()/qmi/:home()",
    meta: index4uajthAnQPMeta || {},
    alias: index4uajthAnQPMeta?.alias || [],
    redirect: index4uajthAnQPMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue").then(m => m.default || m)
  },
  {
    name: index2vLPRVNye1Meta?.name ?? "index",
    path: index2vLPRVNye1Meta?.path ?? "/",
    meta: index2vLPRVNye1Meta || {},
    alias: index2vLPRVNye1Meta?.alias || [],
    redirect: index2vLPRVNye1Meta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: knolls_45hoa_45informationosLbhtWRDiMeta?.name ?? "knolls-hoa-information",
    path: knolls_45hoa_45informationosLbhtWRDiMeta?.path ?? "/knolls-hoa-information",
    meta: knolls_45hoa_45informationosLbhtWRDiMeta || {},
    alias: knolls_45hoa_45informationosLbhtWRDiMeta?.alias || [],
    redirect: knolls_45hoa_45informationosLbhtWRDiMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue").then(m => m.default || m)
  },
  {
    name: land_45acquisitionClmTfmeYIoMeta?.name ?? "land-acquisition",
    path: land_45acquisitionClmTfmeYIoMeta?.path ?? "/land-acquisition",
    meta: land_45acquisitionClmTfmeYIoMeta || {},
    alias: land_45acquisitionClmTfmeYIoMeta?.alias || [],
    redirect: land_45acquisitionClmTfmeYIoMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: brents_45placefGazewesEpMeta?.name ?? "lifestyle-brents-place",
    path: brents_45placefGazewesEpMeta?.path ?? "/lifestyle/brents-place",
    meta: brents_45placefGazewesEpMeta || {},
    alias: brents_45placefGazewesEpMeta?.alias || [],
    redirect: brents_45placefGazewesEpMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue").then(m => m.default || m)
  },
  {
    name: experience_45lower_45maintenanceqht5ak62CeMeta?.name ?? "lifestyle-experience-lower-maintenance",
    path: experience_45lower_45maintenanceqht5ak62CeMeta?.path ?? "/lifestyle/experience-lower-maintenance",
    meta: experience_45lower_45maintenanceqht5ak62CeMeta || {},
    alias: experience_45lower_45maintenanceqht5ak62CeMeta?.alias || [],
    redirect: experience_45lower_45maintenanceqht5ak62CeMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_93ITqA7XMkGEMeta?.name ?? "lifestyle-reviews-testimonial",
    path: _91testimonial_93ITqA7XMkGEMeta?.path ?? "/lifestyle/reviews/:testimonial()",
    meta: _91testimonial_93ITqA7XMkGEMeta || {},
    alias: _91testimonial_93ITqA7XMkGEMeta?.alias || [],
    redirect: _91testimonial_93ITqA7XMkGEMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: index9kWikpUYpOMeta?.name ?? "lifestyle-reviews-category-category",
    path: index9kWikpUYpOMeta?.path ?? "/lifestyle/reviews/category/:category()",
    meta: index9kWikpUYpOMeta || {},
    alias: index9kWikpUYpOMeta?.alias || [],
    redirect: index9kWikpUYpOMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexE9vGpgCd3iMeta?.name ?? "lifestyle-reviews",
    path: indexE9vGpgCd3iMeta?.path ?? "/lifestyle/reviews",
    meta: indexE9vGpgCd3iMeta || {},
    alias: indexE9vGpgCd3iMeta?.alias || [],
    redirect: indexE9vGpgCd3iMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: whats_45lifefullnessyiDRAb4KH9Meta?.name ?? "lifestyle-whats-lifefullness",
    path: whats_45lifefullnessyiDRAb4KH9Meta?.path ?? "/lifestyle/whats-lifefullness",
    meta: whats_45lifefullnessyiDRAb4KH9Meta || {},
    alias: whats_45lifefullnessyiDRAb4KH9Meta?.alias || [],
    redirect: whats_45lifefullnessyiDRAb4KH9Meta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93epe3D61iYlMeta?.name ?? "marshallfire-slug",
    path: _91_46_46_46slug_93epe3D61iYlMeta?.path ?? "/marshallfire/:slug(.*)*",
    meta: _91_46_46_46slug_93epe3D61iYlMeta || {},
    alias: _91_46_46_46slug_93epe3D61iYlMeta?.alias || [],
    redirect: _91_46_46_46slug_93epe3D61iYlMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesDXNXtl56t3Meta?.name ?? "my-favorites",
    path: my_45favoritesDXNXtl56t3Meta?.path ?? "/my-favorites",
    meta: my_45favoritesDXNXtl56t3Meta || {},
    alias: my_45favoritesDXNXtl56t3Meta?.alias || [],
    redirect: my_45favoritesDXNXtl56t3Meta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: new_45home_45searchF2uEf1RyWGMeta?.name ?? "new-home-search",
    path: new_45home_45searchF2uEf1RyWGMeta?.path ?? "/new-home-search",
    meta: new_45home_45searchF2uEf1RyWGMeta || {},
    alias: new_45home_45searchF2uEf1RyWGMeta?.alias || [],
    redirect: new_45home_45searchF2uEf1RyWGMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: easyhouseOwSyQcjp14Meta?.name ?? "our-homes-home-collections-easyhouse",
    path: easyhouseOwSyQcjp14Meta?.path ?? "/our-homes/home-collections/easyhouse",
    meta: easyhouseOwSyQcjp14Meta || {},
    alias: easyhouseOwSyQcjp14Meta?.alias || [],
    redirect: easyhouseOwSyQcjp14Meta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: limitededitionoo6MslNYA7Meta?.name ?? "our-homes-home-collections-limitededition",
    path: limitededitionoo6MslNYA7Meta?.path ?? "/our-homes/home-collections/limitededition",
    meta: limitededitionoo6MslNYA7Meta || {},
    alias: limitededitionoo6MslNYA7Meta?.alias || [],
    redirect: limitededitionoo6MslNYA7Meta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: wee_45cottage9WMpstyJf8Meta?.name ?? "our-homes-home-collections-wee-cottage",
    path: wee_45cottage9WMpstyJf8Meta?.path ?? "/our-homes/home-collections/wee-cottage",
    meta: wee_45cottage9WMpstyJf8Meta || {},
    alias: wee_45cottage9WMpstyJf8Meta?.alias || [],
    redirect: wee_45cottage9WMpstyJf8Meta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: indexrTWPO4Na4VMeta?.name ?? "our-homes",
    path: indexrTWPO4Na4VMeta?.path ?? "/our-homes",
    meta: indexrTWPO4Na4VMeta || {},
    alias: indexrTWPO4Na4VMeta?.alias || [],
    redirect: indexrTWPO4Na4VMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: our_45neighborhoodsEPgCLkYf3QMeta?.name ?? "our-neighborhoods",
    path: our_45neighborhoodsEPgCLkYf3QMeta?.path ?? "/our-neighborhoods",
    meta: our_45neighborhoodsEPgCLkYf3QMeta || {},
    alias: our_45neighborhoodsEPgCLkYf3QMeta?.alias || [],
    redirect: our_45neighborhoodsEPgCLkYf3QMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue").then(m => m.default || m)
  },
  {
    name: partner_45with_45usgLJbOzxj81Meta?.name ?? "partner-with-us",
    path: partner_45with_45usgLJbOzxj81Meta?.path ?? "/partner-with-us",
    meta: partner_45with_45usgLJbOzxj81Meta || {},
    alias: partner_45with_45usgLJbOzxj81Meta?.alias || [],
    redirect: partner_45with_45usgLJbOzxj81Meta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue").then(m => m.default || m)
  },
  {
    name: privacytermsn6n23K94bqMeta?.name ?? "privacyterms",
    path: privacytermsn6n23K94bqMeta?.path ?? "/privacyterms",
    meta: privacytermsn6n23K94bqMeta || {},
    alias: privacytermsn6n23K94bqMeta?.alias || [],
    redirect: privacytermsn6n23K94bqMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: sandbox4FoKU4KJEgMeta?.name ?? "sandbox",
    path: sandbox4FoKU4KJEgMeta?.path ?? "/sandbox",
    meta: sandbox4FoKU4KJEgMeta || {},
    alias: sandbox4FoKU4KJEgMeta?.alias || [],
    redirect: sandbox4FoKU4KJEgMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93OfSJz3W8ZrMeta?.name ?? "videos-slug",
    path: _91_46_46_46slug_93OfSJz3W8ZrMeta?.path ?? "/videos/:slug(.*)*",
    meta: _91_46_46_46slug_93OfSJz3W8ZrMeta || {},
    alias: _91_46_46_46slug_93OfSJz3W8ZrMeta?.alias || [],
    redirect: _91_46_46_46slug_93OfSJz3W8ZrMeta?.redirect,
    component: () => import("/codebuild/output/src1368469734/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/about-us/get-our-brochure/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/about-us/in-the-news/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/about-us/realtor-program/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/about-us/what-we-do/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/about-us/who-we-are/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/build-with-us/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/career-opportunities/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/career-opportunities/administrative-assistant-2/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/career-opportunities/director-of-warranty-services/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/career-opportunities/land-development-entitlements-coordinator/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/contact-us/ask-a-question/thank-you/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/contact-us/warranty-services/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/email-signup/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/get-updates/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/get-updates/thank-you/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/how-to-video-library/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/lifestyle/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/locations/lanterns-at-rock-creek/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/locations/silver-leaf/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/locations/wee-cottage-at-north-end-80238/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/locations/wee-cottage-at-rogers-farm/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/locations/west-edge-at-colliers-hill/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/login/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/lost-password/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/maintenance-items/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/marshall-fire-response/thank-you-loi/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/neighborhoods-map-test/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/organism-page/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/our-homes/home-collections/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/our-homes/home-collections/lanterns-series/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/our-homes/home-collections/opportunities-series/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/our-homes/photo-gallery/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/our-homes/photo-gallery/gourmet-kitchens/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/our-homes/photo-gallery/home-offices/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/our-homes/photo-gallery/living-rooms/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/our-homes/photo-gallery/outdoor-living-spaces/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/our-homes/photo-gallery/relaxing-master-suites/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/register/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/reset-password/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/resources-lower-maintenance/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/style-guide/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  },
  {
    name: component_45stubQQN2lCIRr2Meta?.name ?? undefined,
    path: component_45stubQQN2lCIRr2Meta?.path ?? "/warranty-service-request/",
    meta: component_45stubQQN2lCIRr2Meta || {},
    alias: component_45stubQQN2lCIRr2Meta?.alias || [],
    redirect: component_45stubQQN2lCIRr2Meta?.redirect,
    component: component_45stubQQN2lCIRr2
  }
]